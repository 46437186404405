export const jwtPassword = process.env.REACT_APP_JWT_PSS as string;

export const baseURL = process.env.REACT_APP_API || "http://localhost:3000/";

export const localImgs = process.env.REACT_APP_API_IMAGES;
// export const baseURL = "https://www.ihsm.sombradoble.es/bookingsApi/";

export const editorConfig = {
  toolbar: [
    [
      "Bold",
      "Italic",
      "Underline",
      "NumberedList",
      "BulletedList",
      "Blockquote",
      "Link",
      "Image",
      "Format",
      "Maximize",
    ],
  ],
  versionCheck: false,
};

export const editorConfigShort = {
  toolbar: [["Bold", "Italic", "Underline"]],
  versionCheck: false,
};

export const ckInput = {
  width: "100%",
  marginTop: "12px",
  paddingTop: "12px",
  paddingLeft: "12px",
  backgroundColor: "#eeeeee",
};

export const SwalError = "Ha habido un error, por favor intente de nuevo más tarde.";
export const fillRequiredText = "Por favor rellene rellene los campos necesarios";
export const requiredInputs = "Por favor, rellene todos los campos con *";
