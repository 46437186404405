export var normalizer = (function () {
  var from = "ÃÀÁÄÂÈÉËÊÌÍÏÎÒÓÖÔÙÚÜÛãàáäâèéëêìíïîòóöôùúüûÑñÇç",
    to = "AAAAAEEEEIIIIOOOOUUUUaaaaaeeeeiiiioooouuuunncc",
    mapping: any = {};

  for (var i = 0, j = from.length; i < j; i++)
    mapping[from.charAt(i)] = to.charAt(i);

  return function (str: string) {
    var ret = [];
    for (var i = 0, j = str.length; i < j; i++) {
      var c = str.charAt(i);
      if (mapping.hasOwnProperty(str.charAt(i))) ret.push(mapping[c]);
      else ret.push(c);
    }
    return ret.join("");
  };
})();

export const searchInvestigators = (
  investigators: any[],
  searchText: string
) => {
  let filteredInvestigators: any[] = [];
  investigators.forEach((investigator: any) => {
    if (
      normalizer(investigator?.name.toLowerCase()).includes(
        searchText.toLowerCase()
      ) ||
      normalizer(investigator?.surname1.toLowerCase()).includes(
        searchText.toLowerCase()
      ) ||
      normalizer(investigator?.surname2?.toLowerCase())?.includes(
        searchText?.toLowerCase()
      )
    ) {
      filteredInvestigators.push(investigator);
    }
  });

  return filteredInvestigators;
};
