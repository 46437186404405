export const findIfSelectedByGroup = (
  { investigationGroupPublications }: any,
  id: string
) => {
  let selected = false;
  if (investigationGroupPublications) {
    let foundPublication = investigationGroupPublications.find(
      ({ publication }: any) => publication.id === +id
    );

    if (foundPublication) selected = foundPublication.selected;
  }

  return selected;
};
