export const separateRoles = (investigators) => {
  let ips = investigators
    .filter(({ projectRole }) => projectRole === "ip")
    .map(({ investigator }) => investigator);
  let participants = investigators
    .filter(({ projectRole }) => projectRole === "participant")
    .map(({ investigator }) => investigator);
  return { ips: cleanUsers(ips), participants: cleanUsers(participants) };
};

export const cleanUsers = (users) => {
  let cleanedUsers = [];
  users.map(({ id, name, surname1, surname2 }) =>
    cleanedUsers.push({ id, name, surname1, surname2 })
  );
  return cleanedUsers;
};

export const uniqueUsers = (set1, set2) =>
  [].concat(
    set1?.filter((set) => set2.every(({ id }) => set.id !== id)),
    set2.filter((set) => set1?.every(({ id }) => set.id !== id))
  );

export const buildInvestigatorProject = (participants, ips, projectId) => {
  participants = participants.map(({ id }) => ({
    projectRole: "PARTICIPANT",
    investigatorId: id,
    projectId,
  }));
  ips = ips.map(({ id }) => ({
    projectRole: "IP",
    investigatorId: id,
    projectId,
  }));
  return participants.concat(ips);
};
