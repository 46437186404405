// prettier-ignore
export const validate = ({ titleEs, contentEs, communicationTypeId}: any) => {
    let aux = "";
    if (!titleEs) aux = "Por favor rellene el título en Español.";
    if (!contentEs) aux = "Por favor rellene el texto en Español.";
    if (!communicationTypeId) aux = "Por favor indique el tipo de noticia.";

    return aux;
  };

